import React from 'react';
import './print.css';
import moment from 'moment'; // Ensure moment is installed

const PrintComponent = ({ formStructure, transactionData, template, onClose }) => {
  console.log("Transaction Print", transactionData, formStructure);

  if (!formStructure || !transactionData || !template) {
    return <div>No data available or invalid data structure.</div>;
  }

  const { form_fields = [], form_grids = [], name: formName } = formStructure;
  const { reportHeader, pageHeader, details, pageFooter, reportFooter, reportFooterText } = template;

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const printContent = document.getElementById("printableContent").innerHTML;

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${formName || 'Print'}</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 0; padding: 10px; }
            table { width: 100%; border-collapse: collapse; margin-top: 10px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const getNestedValue = (key, obj = transactionData) => {
    const searchKey = (obj) => {
      if (Array.isArray(obj)) {
        for (let item of obj) {
          const result = searchKey(item);
          if (result !== undefined) return result;
        }
      } else if (typeof obj === 'object' && obj !== null) {
        if (key in obj) return obj[key];
        for (let k in obj) {
          const result = searchKey(obj[k]);
          if (result !== undefined) return result;
        }
      }
      return undefined;
    };
    return searchKey(obj) || 'N/A';
  };

  const formatNumber = (number, pattern) => {
    const [integerPart, decimalPart] = pattern.split('.');
    const decimalPlaces = decimalPart ? decimalPart.length : 0;

    const formattedNumber = number
      .toFixed(decimalPlaces) // Ensure the number has the correct decimal places
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas as thousand separators

    return formattedNumber;
  };

  const renderFormFields = () =>
    form_fields
      .filter((field) => field.visible)
      .map((field) => {
        const { name, label } = field;
        const value = getNestedValue(name);

        return (
          <tr key={field.name}>
            <th style={{ textAlign: 'left', padding: '8px' }}>{label}</th>
            <td style={{ padding: '8px' }}>{value}</td>
          </tr>
        );
      });

  const renderFormGrids = () => {
    const visibleGrids = form_grids?.filter((grid) => grid.visible);
    if (!visibleGrids?.length) return null;

    console.log("Grids data", form_grids);

    const gridData = Array.isArray(transactionData[0]?.transaccounts)
      ? transactionData[0].transaccounts
      : transactionData;

    return (
      <table id="reporttable" className="grid-table">
        <thead>
          <tr>
            {visibleGrids?.map((grid) => (
              <th key={grid.id || grid.name}>{grid.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {gridData?.map((item, index) => (
            <tr key={index}>
              {visibleGrids?.map((grid) => {
                const value = item[grid.name] || 'N/A';

                // Apply formatting based on input type
                const formattedValue =
                  grid.inputtype === 'Number' && value !== 'N/A'
                    ? formatNumber(parseFloat(value), grid.dataformat)
                    : value;

                return (
                  <td
                    key={grid.id || grid.name}
                    style={{
                      textAlign: grid.inputtype === 'Number' ? 'right' : 'left',
                      padding: '8px',
                      maxWidth: '150px',
                    }}
                  >
                    {formattedValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (

    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto', maxHeight: '50vh' }}>
      <div id="printableContent">
        {reportHeader && (
          <div className="report-header">
            <table className="report-table">
              <tbody>{renderFormFields()}</tbody>
            </table>
          </div>
        )}

        {pageHeader && <div className="page-header"></div>}

        {details && <div className="details">{renderFormGrids()}</div>}

        {pageFooter && <div className="page-footer">{pageFooter}</div>}
        {reportFooter && <div className="report-footer">{reportFooterText}</div>}
      </div>

      <div className='static'>
        <div className="printdiv  ">
          <button className="printbtn" onClick={handlePrint}>
            Print
          </button>
          <button className="printbtn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintComponent;
