import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuFunction } from '../../Redux/Reducer/activateMenuSlice';
import './comman.css';
import UpdateImg from '../../asset/alert icons/update.png';
import SaveImg from '../../asset/alert icons/save.png';
import DeleteImg from '../../asset/alert icons/delete.png';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function LogOutAlertBoxRefToken({setLogoutModalExRefreshToken}) {
    const dispatch = useDispatch();
    const navegate = useNavigate()
    const darkModeState = useSelector((state) => state.darkMode);
    const { t } = useTranslation();

    const handleNAvigate =async()=>{
        navegate('/', { replace: true });
    }
    return (
        <div className='absolute top-0 h-screen d-flex justify-center items-center xl:w-9/12 lg:w-9/12 sm:w-full'>
            <div
                className='relative rounded-[30px] bg-gray-100 shadow xl:w-5/12 lg:w-6/12 sm:w-12/12 h-fit z-50 d-flex flex-col justify-center items-center py-3'
                style={{
                    background: darkModeState.checkvalue ? dayTheme.masterListRowColor : darkTheme.masterListRowColor,
                }}
            >
                <div className='w-full d-flex justify-center'>
                    <div>
                        <h1 className='text-center py-2 font-black mt-3'>{t('logoutMessage')}</h1>
                        <div className='d-flex justify-center items-center'>
                            <button
                                className='alertBGColor text-white hover:bg-[#362180] px-3 py-1 shadow m-2 rounded-full font-bold'
                            onClick={()=>handleNAvigate()}>
                                LogOut
                            </button>
                            <button
                                className='alertBGColor text-white hover:bg-[#362180] px-3 py-1 shadow m-2 rounded-full font-bold'
                            onClick={()=>setLogoutModalExRefreshToken(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div className='absolute top-[-20px]'>
                    <img src={UpdateImg} alt="" width={50} />
                </div>
            </div>
        </div>
    );
}

export default LogOutAlertBoxRefToken;
