import axios from 'axios';
import { BaseURL,refreshToken } from './Components/Masters/masterPagefunctions';


const getMenuImageStructure = async () => {
    const email = sessionStorage.getItem('email')
    try {
        const responseobjerct = JSON.parse(sessionStorage.getItem('responseobjerct'));
        const expiresat = responseobjerct?.access_token_expires_at.split(' ')[1];
        const currentTime = new Date().toUTCString().split(' ')[4];
        let response
        if (currentTime < expiresat) {
            response = await axios.get(`https://confighub.${BaseURL}/api/v1/images`, {
                headers: {
                    Authorization: `Bearer ${responseobjerct?.access_token}`,
                    identifier: email
                },
            });
        }else{
            const refresh_token = responseobjerct?.refresh_token;
            await refreshToken(refresh_token);
            await getMenuImageStructure();
        }

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Request failed with status:', response.status);
            return [];
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export const reduceMenuData = async () => {
    const menuImage = await getMenuImageStructure();
    const images = {};

    const processedData = await Promise.all(menuImage.map(async (item) => {
        try {
            const image = await import(`../src/asset/Menusvg/main/${item.imagename}`);
            images[item.key] = image.default;
        } catch (error) {
            console.error(`Error importing image: ${item.key}`, error);
        }
        return { image: images[item.key], name: item.imagename };
    }));

    console.log('Processed Data:', processedData);
    console.log('Images:', images);

    return processedData;
};






