import axios from "axios";
import { BaseURL,refreshToken } from "../Masters/masterPagefunctions";





export const getMenuItems = async (setMenuItems,setLogoutModalExRefreshToken) => {
  const userid = sessionStorage.getItem('userid');
  const email = sessionStorage.getItem('email')
  try {
    const responseobjerct = JSON.parse(sessionStorage.getItem('responseobjerct'));
    const expiresat = responseobjerct?.access_token_expires_at.split(' ')[1];
    const currentTime = new Date().toUTCString().split(' ')[4];
    let response
    if (currentTime < expiresat) {
      response = await axios.get(
        `https://operations.${BaseURL}/api/menus/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${responseobjerct?.access_token}`,
            identifier: email
          },
        }
      );
    }else{
      const refresh_token = responseobjerct?.refresh_token;
      await refreshToken(refresh_token, setLogoutModalExRefreshToken);
      await getMenuItems(setMenuItems);
    }


    if (response.status === 200) {
      console.log("Menu data", response.data);
      setMenuItems(response.data)
    } else {
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};