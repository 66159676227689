import React from 'react'
import Gif from '../../asset/Gif/output-onlinegiftools.gif'
function BeforeLoadTableData() {
  return (
    <div className='absolute top-[25%] left-[35%] z-50 '>
        <img src={Gif} alt="" srcset="" width={300} height={80} />

    </div>
  )
}

export default BeforeLoadTableData