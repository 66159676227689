import React from 'react';
import { useSelector } from 'react-redux';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
import { DatePickerInput } from '@mantine/dates';
import { useTranslation } from 'react-i18next';

const CustomDateRangePicker = ({ dateRange, onChange, onClose, onApply, setSelectedDateFilter }) => {
  const darkModeState = useSelector((state) => state.darkMode);
  const { t } = useTranslation();

  const handleApply = () => {
    if (!dateRange?.[0] || !dateRange?.[1]) {
      return;
    }
    setSelectedDateFilter('Custom');
    const fromDate = dateRange[0].toISOString().split('T')[0];
    const toDate = dateRange[1].toISOString().split('T')[0];
    onApply({ fromDate, toDate });
    onClose();
  };

  // Common styles for DatePickerInput
  const datePickerStyles = {
    input: {
      height: '40px',
      backgroundColor: darkModeState.checkvalue 
        ? dayTheme.backgroundColor 
        : darkTheme.backgroundColor,
      border: `1px solid ${darkModeState.checkvalue 
        ? dayTheme.borderColor 
        : darkTheme.borderColor}`,
      fontSize: '14px',
      borderRadius: '6px',
      padding: '0 12px',
      color: darkModeState.checkvalue 
        ? dayTheme.tableTextColor 
        : darkTheme.tableTextColor
    },
    dropdown: {
      fontSize: '14px',
      maxHeight: '300px',
      width: '280px',
      padding: '8px',
      transform: 'none',
      position: 'absolute',
      top: 'calc(100% + 4px)',
      zIndex: 1000,
      backgroundColor: darkModeState.checkvalue 
        ? dayTheme.Dbgcolor 
        : darkTheme.Dbgcolor
    },
    calendar: {
      maxHeight: '250px',
      backgroundColor: darkModeState.checkvalue 
        ? dayTheme.Dbgcolor 
        : darkTheme.Dbgcolor
    },
    day: {
      height: '30px',
      width: '30px',
      fontSize: '13px',
      color: darkModeState.checkvalue 
        ? dayTheme.tableTextColor 
        : darkTheme.tableTextColor,
      '&:hover': {
        backgroundColor: darkModeState.checkvalue 
          ? dayTheme.hoverColor 
          : darkTheme.hoverColor
      },
      '&[data-selected]': {
        backgroundColor: darkModeState.checkvalue 
          ? dayTheme.selectedColor 
          : darkTheme.selectedColor
      }
    }
  };

  const popoverStyles = {
    dropdown: {
      border: `1px solid ${darkModeState.checkvalue 
        ? dayTheme.nestedMenuBorderColor 
        : darkTheme.nestedMenuBorderColor}`,
      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
      borderRadius: '6px',
      backgroundColor: darkModeState.checkvalue 
        ? dayTheme.Dbgcolor 
        : darkTheme.Dbgcolor
    }
  };

  return (
    <div 
      className="p-6" 
      style={{ 
        display: 'flex', 
        flexDirection: 'column',
        width: '600px',
        maxWidth: '90vw',
        backgroundColor: darkModeState.checkvalue 
          ? dayTheme.Dbgcolor 
          : darkTheme.Dbgcolor,
        color: darkModeState.checkvalue 
          ? dayTheme.tableTextColor 
          : darkTheme.tableTextColor
      }}
    >
      <div style={{ flex: 1 }}>
        <h2 className="text-xl font-medium mb-6">Select Date Range</h2>
        <div className="flex flex-col space-y-6">
          <div className="grid grid-cols-2 gap-8">
            <div>
              <label className="block text-sm font-medium mb-2">From</label>
              <DatePickerInput
                value={dateRange?.[0]}
                onChange={(date) => {
                  const newRange = [date, dateRange?.[1] || date];
                  onChange(newRange);
                }}
                placeholder="Pick date"
                className="w-full"
                clearable={false}
                maxDate={dateRange?.[1]}
                styles={datePickerStyles}
                popoverProps={{
                  withinPortal: true,
                  styles: popoverStyles
                }}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">To</label>
              <DatePickerInput
                value={dateRange?.[1]}
                onChange={(date) => {
                  const newRange = [dateRange?.[0], date];
                  onChange(newRange);
                }}
                placeholder="Pick date"
                className="w-full"
                clearable={false}
                minDate={dateRange?.[0]}
                styles={datePickerStyles}
                popoverProps={{
                  withinPortal: true,
                  styles: popoverStyles
                }}
              />
            </div>
          </div>
          
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '12px',
              marginTop: '16px',
              padding: '16px 0 0',
              borderTop: `1px solid ${darkModeState.checkvalue 
                ? dayTheme.LabelBarColor 
                : darkTheme.LabelBarColor}`
            }}
          >
            <button
              className="rounded-full text-white mx-1 px-1"
              onClick={onClose}
              style={{ 
                height: '20px', 
                width: '67px', 
                fontSize: '10px', 
                background: darkModeState.checkvalue 
                  ? dayTheme.ReportButtonColor 
                  : darkTheme.ReportButtonColor 
              }}
            >
              {t('cancel')}
            </button>

            <button
              className="rounded-full text-white mx-1 px-1"
              onClick={handleApply}
              style={{ 
                height: '20px', 
                width: '67px', 
                fontSize: '10px', 
                background: darkModeState.checkvalue 
                  ? dayTheme.ReportButtonColor 
                  : darkTheme.ReportButtonColor 
              }}
            >
              {t('apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDateRangePicker;