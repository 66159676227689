// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const language = JSON.parse(sessionStorage.getItem('responseobjerct'))?.user_language
console.log("Language",language)
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "transamountError": "Value must be greater than or equal to zero",
          "docdateError": "Enter a correct date",
          // "accidError": "select Account",
          // "nameError":"Pleace Enter Account Name",
          // "invalidMobileError":"enter 10 digit number",
          // "EmailError":"Eneter correct Email",
          // "websiteError":"Enter correct website formate 'https://www.danabook.com'",
          // "creditperiodError":"Only Enter Integer",
          // "creditexceptionError":"Only enter decimalpoint two"
          "multipleLageError":"Enter a correct ",
          "darkmode":"DarkMode",
          "daymode":"Day Mode",
          "getreport" :"Get Report",
          "today":"Today",
          "currentmonth":"Current Month",
          "previous":"Previous Month",
          "save":"Save",
          "close":"Close",
          "delete":"Delete",
           "oops":"Oops!",
          "norecord":"No Records!",
          "active":"Active",
          "inactive":"InActive",
          "logoutmsg":"Do you want to Logout?",
          "logout":"Logout",
          "cancel":"Cancel",
           "action":"Action",
           "logoutMessage":"Please Log Out",
            "print":"Print",
            "custom":"Custom",
            "apply":"Apply"
        }
      },
      hi: {
        translation: {
          "transamountError": "मान शून्य के बराबर या उससे अधिक होना चाहिए",
          "docdateError": "सही तारीख दर्ज करें",
        }
      },
      ar: {
        translation: {
          "transamountError": "يجب أن تكون القيمة أكبر من أو تساوي الصفر",
          "docdateError": "أدخل تاريخًا صحيحًا",
          "accidError": "أدخل تاريخًا صحيحًا",
          "darkmode":"الوضع الداكن",
          "daymode":"وضع اليوم",
          "multipleLageError":"أدخل حرفا صحيحا ",
          "today":"اليوم",
          "currentmonth":"الشهر الحالي",
          "previous":"الشهر السابق",
          "save":"أنقذ",
          "close":"غلق",
          "delete":"حذف",
           "oops":"!عفوا",
          "norecord":"!لا توجد سجلات",
          "active":"نشط",
          "inactive":"كسلان",
          "logoutmsg":"هل تريد تسجيل الخروج؟",
          "logout":"الخروج",
          "cancel":"إلغاء الأمر",              
          "action":"الإجراءات",
          "logoutMessage":"يرجى تسجيل الخروج",
           "print":"مطبعة",
           "custom":"تقليد",
           "apply":"يتقدم"
        }
      },
      ml: {
        translation: {
          "transamountError": "മൂല്യം പൂജ്യത്തേക്കാൾ കൂടുതലോ തുല്യമോ ആയിരിക്കണം",
          "darkmode":"ഡാർക്ക് മോഡ്",
          "daymode":"ഡേ മോഡ്",
          "multipleLageError":"ശരിയാണെന്ന് നോക്കുക",
          "today":"ഇന്ന്",
          "currentmonth":"നിലവിലെ മാസം",
          "previous":"കഴിഞ്ഞ മാസം",
          "getreport" :"റിപ്പോർട്ട്",
          "save":"സേവ്",
          "close":"ക്ലോസ്",
          "delete":"ഡിലീറ്റ്",
          "oops":"അയ്യോ!",
          "norecord":"രേഖകൾ ഇല്ല!",
          "active":"സജീവം",
          "inactive":"നിഷ് ക്രിയം",
          "logoutmsg":"നിങ്ങൾക്ക് ലോഗൗട്ട് വേണോ?",
          "logout":"ലോഗോട്ട്",
          "cancel":"ക്യാൻസൽ ചെയ്യ്",
           "action":"പ്രവൃത്തി",
          "logoutMessage":"ദയവായി ലോഗ് ഔട്ട് ചെയ്യുക",
           "print":"അച്ചടിക്കുക",
           "custom":"നടപടിക്രമം",
           "apply":"അപേക്ഷിക്കുക"
        }
      },
      // Add other languages here
    },
    
    lng: language, // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
