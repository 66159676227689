import React, { useEffect, useState, useMemo, forwardRef, useRef } from 'react';
import Select from 'react-select';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import AddIcon from '@mui/icons-material/Add';
import { masterFormDesign } from '../../PageStyle/pageStyleVariable';
import { useDispatch, useSelector } from 'react-redux';
import { useInputBoxValidation } from '../../Hook/useInputBoxValidation';
import { BaseURL, groupListData, handleEnterKeyPressMAaster } from './masterPagefunctions';
import './master.css';
import { selectFliterFunction } from '../../Redux/Reducer/filterSlice';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';

// Custom Menu component for adding new options
const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
  !isDisabled ? (
    <div ref={innerRef} {...innerProps} className="customReactSelectMenu rounded mt-1 w-[324px] border-1 border-[#43418e] text-black absolute z-50 bg-white">
      <div>{children}</div>
      {/* <div className='flex justify-between items-center px-3 text-black'>
        <span>Add</span>
        <button
          onClick={() => {
            // Add your logic here for the "Add New" button click
            console.log("Add New button clicked");
          }}
        >
          <AddIcon className='hover:text-[red]' />
        </button>
      </div> */}
    </div>
  ) : null;



function SelectDropDown({ field, formData, formDetails, handleInputChange, getAccDataUpdate, setgetAccDataUpdate, setIsValidateRequired, isValidateRequired, setAccountList, setSearchValue, searchValue, setFormData, selectboxindex, accountList, setLogoutModalExRefreshToken,formInputRef }, ref) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [searchSingleComponent,setsearchSingleComponent] = useState('');
  

  const selectState = useSelector((state) => state.selectMenu);
  const selectFilterName = useSelector((state) => state.Filternames)
  const dispatch = useDispatch();


 console.log("searchSingleComponent",searchSingleComponent);

  // create selectore foe Arabic Alignment
  const arabicAlignMent = useSelector((state) => state.arabicAlignMent)
  // darkTheme
  const darkModeState = useSelector((state) => state.darkMode)

  // Determine the API endpoint for the current field
  const apiEndpoint = field.apiendpoint;
  const apiServiceName = field.apiservicename;
  const apiArguments = field.apiarguments;

  const apiURL = useMemo(() => {
    if (!apiEndpoint) return null;
    return apiArguments
      ? `https://${apiServiceName}.${BaseURL}${apiEndpoint}?${apiArguments}`
      : `https://${apiServiceName}.${BaseURL}${apiEndpoint}`;
  }, [apiEndpoint, apiServiceName, apiArguments]);
  console.log("Select Box API", apiURL);
  useEffect(() => {
    if (apiURL) {
      groupListData(apiURL, setGroupList, setAccountList,field,setLogoutModalExRefreshToken);
    }
  }, [apiURL,accountList]);

  // const options = useMemo(() => groupList.flatMap(item => {
  //   if (item.active && item.active.length > 0) {
  //     return item.active.map(acItem => ({
  //       value: acItem.id,
  //       label: acItem.name,
  //       isDisabled: (selectState.name === "Delete" || selectState.name === "View") ? true : field.readonly,
  //     }));
  //   }
  //   return [{
  //     value: item.id,
  //     label: item.name,
  //     isDisabled: (selectState.name === "Delete" || selectState.name === "View") ? true : field.readonly,
  //   }];
  // }), [groupList, selectState, field])




  const options = useMemo(() => {
    return groupList?.flatMap((item) => {
      if (item.active && item.active.length > 0) {
        return item.active.map((acItem) => ({
          value: acItem?.[field.apidatakey],
          label: acItem?.[field.apilabelkey],
          isDisabled: selectState.name === 'Delete' || selectState.name === 'View' ? true : field.readonly,
        }));
      }
      return [
        {
          value: item?.[field.apidatakey],
          label: item?.[field.apilabelkey],
          isDisabled: selectState.name === 'Delete' || selectState.name === 'View' ? true : field.readonly,
        },
      ];
    })
      .filter((option) =>  option?.label?.toLowerCase().includes(searchSingleComponent?.toLowerCase()));
     // Filter options by searchValue
  }, [groupList, selectState, field, searchSingleComponent]);



  // useEffect(() => {
  //   // Assuming `options` is an array that you want to set in the Redux store
  //   dispatch(multiSelectboxArrayFunction({ 
  //     multiselectboxdataArray: groupList // Correctly structure the payload
  //   }));
  // }, [dispatch, groupList])

 useEffect(()=>{
  if(selectState.name !== "Insert"){
    dispatch(selectFliterFunction({filterId: getAccDataUpdate?.data?.[0][field.name] }));
  }
 },[groupList])





//  useEffect(() => {
//   if (getAccDataUpdate) {
//     const updatedData = { ...getAccDataUpdate }; // Create a shallow copy of getAccDataUpdate
//     updatedData.data[0][field.name] = selectFilterName.filterId; // Update the field with the new filterId

//     setgetAccDataUpdate(updatedData); // Use the setter function to update the state
//   }
// }, [groupList,selectFilterName.filterId]);



// let formupdateData =  getAccDataUpdate?.data[0][field.name] ? (getAccDataUpdate?.data[0][field.name] == selectFilterName.filterId ? getAccDataUpdate?.data[0][field.name] : selectFilterName.filterId ) : selectFilterName.filterId 

//   console.log("formupdateData",formupdateData)

  const initialOption = useMemo(() => selectState.name !== "Insert" ?
    options?.find(option => option.value ==  getAccDataUpdate?.data?.[0][field.name]) :
    options?.find(option => option.label === selectFilterName.filtername), [options, selectState, getAccDataUpdate, field.name, selectFilterName.filtername]);

    // console.log("initialOption", groupList);



 // Clearselect box data 
 const handleclearInputbox=()=>{
  setSearchValue('');
  setsearchSingleComponent('');

}




const handleEnterKeyPress = (event, index, field) => {
  if (event.key === "Enter") {
    // event.preventDefault();
    if (field.inputtype == "Dropdown") {
      if (event.key === "Enter") {

        // event.preventDefault();
        const selected = formInputRef?.current[index]?.focusedOptionRef?.id;
        const activeOptionElement = document.getElementById(selected);
        const activeOptionText = activeOptionElement ? activeOptionElement.textContent : null;


        if (activeOptionText) {

          const currentFieldValue = formInputRef?.current[index]?.props?.value?.label;
          const currentFieledID = formInputRef?.current[index]?.props?.value?.value;



          if (currentFieldValue !== activeOptionText) {
            handleInputChange({
              target: {
                name: field.name,
                value: activeOptionText,
                onClick: dispatch(selectFliterFunction({ filtername: activeOptionText, filterId:initialOption?.value })) && setSearchValue(''),
              }
            }, field);
          }

          // console.log("selectedsd", initialOption?.value);
          

          // handleInputChange({

          //   target: {
          //     name: field.name,
          //     value: activeOptionText? activeOptionText: null,
          //     onClick: dispatch(selectFliterFunction({ filtername: activeOptionText })) && setSearchValue(''),
          //   }

          // }, field)
        }

      }
    }

    // Validate required field
    // if (field.required && !formData[field.name]) {
    //   setIsValidateRequired(true);
    //   return; // Prevent moving to the next field
    // }

    let nextIndex = index + 1;

    // Move forward to the next field until a non-read-only field is found
    while (
      nextIndex < formInputRef.current.length &&
      formInputRef.current[nextIndex] &&
      formInputRef.current[nextIndex].readOnly
    ) {
      nextIndex++;
    }

    // Handle case where nextIndex is out of range or the field is null/undefined
    if (nextIndex >= formInputRef.current.length || !formInputRef.current[nextIndex]) {
      let prevIndex = index;

      // Go backward to find the last non-read-only field
      while (
        prevIndex >= 0 &&
        formInputRef.current[prevIndex] &&
        formInputRef.current[prevIndex].readOnly
      ) {
        prevIndex--;
      }

      // Focus on the previous editable field if found
      if (prevIndex >= 0 && formInputRef.current[prevIndex]) {
        formInputRef.current[prevIndex].focus();
      } else {
        // If no editable field is found, show pointer at the current field
        event.target.style.cursor = "pointer";
      }
    } else if (formInputRef.current[nextIndex]) {
      // Focus on the next editable field if it exists
      formInputRef.current[nextIndex].focus();
    }
  }
};

    
    
  const selectinitialValue = selectState.name !== "Insert" ? formData[field.name] : initialOption;

  useEffect(() => {
    if (selectinitialValue) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [field.name]: selectinitialValue.value
      }));
    }
  }, [selectinitialValue, field.name, setFormData]);

  useEffect(() => {
    // Open dropdown when search value changes and options are available
    if (searchSingleComponent && options.length > 0 && groupList) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }, [searchSingleComponent, options]);

  const handleFocus = () => {
    setMenuIsOpen(true); 
    setAccountList({accSeleBox:groupList,accSelectFileds:field}); 
    handleclearInputbox();
    if(selectState.name !== "Insert"){
      dispatch(selectFliterFunction({filterId: getAccDataUpdate?.data?.[0][field.name] }));
      handleclearInputbox();
    } // Open the dropdown on focus
  };

  const handleClick = () => {
    setMenuIsOpen((prev) => !prev);
    setAccountList({accSeleBox:groupList,accSelectFileds:field});
    handleclearInputbox();
    if(selectState.name !== "Insert"){
      dispatch(selectFliterFunction({filterId: getAccDataUpdate?.data?.[0][field.name] }));
      handleclearInputbox();
    } // Open the dropdown on focus  // Toggle the dropdown on click
  };

  // Handle closing the dropdown when it loses focus
  const handleBlur = () => {
    setMenuIsOpen(false);  // Close the dropdown on blur
  };


 



  const customStyles = useMemo(() => ({
    control: (base, state) => ({
      ...base,
      height: 25,
      minHeight: 25,
      width: '100%',
      maxWidth: '100%',
      borderRadius: '6px',
      backgroundColor: `${(selectState.name === "Delete" || selectState.name === "View" || field.readonly) ? dayTheme.inputFildReadColor : darkModeState.checkvalue ? dayTheme.inputFildColor : darkTheme.inputFildColor}`,
      border: state.isFocused ? 0 : '1px solid #43418e',
      boxShadow: state.isFocused ? '0 0 0 2px #bfdbfe' : 0,
      outline: '#fb7185',
      '&:hover': { outline: '#fb7185' },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '20px',
      width: '100%',
      maxWidth: '100%',
      padding: '0 6px',
      margin: '0px 0px',
      fontSize: 14,
      color: 'black',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '20px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px 0px 0px 0px',
      padding: '0px 0px 0px 0px',
      height: '20px',
      color: `${darkModeState.checkvalue ? 'black' : 'white'}`,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor : "white",
      color: state.isSelected ? 'black' : 'black',
      padding: '3px 12px',
      '&:hover': { backgroundColor: darkModeState.checkvalue ? dayTheme.LabelBarColor : darkTheme.LabelBarColor },
    }),
  }), [selectState, field, darkModeState.checkvalue]);

  const validate = useInputBoxValidation();


  return (
    <div className={'sm:w-full lg:w-10/12 xl:w-10/12 2xl:w-10/12'}>
      <div className='relative sm:w-[100%] lg:w-[325px] xl:w-[325px]'>
        <style>
          {/* {
            ` 
            .css-1dimb5e-singleValue{
                color:${darkModeState.checkvalue ? 'black' : 'white'};
            }
              `
          } */}
        </style>
        {groupList && groupList.length > 0 ? (
          <Select
            ref={ref}
            className={`${field.required ? 'outline-rose-400' : 'outline-blue-200'} rounded-md text-gray-400`}
            options={options}
            menuPlacement="bottom"
            menuIsOpen={menuIsOpen}
            value={selectState.name !== "Insert" ? initialOption : (
              field.jsonref === null
                ? formData[field.name] ? options.find(option => option.value === formData[field.name]) : initialOption
                : formData[field.jsonref]?.[field.name] ? options.find(option => option.value === formData[field.jsonref][field.name]) : initialOption
            )}

            onChange={selectState.name !== "Insert"
              ? (selectedOption) => {
                const updatedData = { ...getAccDataUpdate };
                updatedData.data[0][field.name] = selectedOption ? selectedOption.value : null;
                console.log("update select box data",  updatedData.data[0][field.name],selectFilterName.filterId)
                setgetAccDataUpdate(updatedData);
                
                dispatch(selectFliterFunction({ filtername: selectedOption.label, filterId: selectedOption.value }));
                handleclearInputbox();
              }
              : (selectedOption) => handleInputChange({

                target: {
                  name: field.name,
                  value: selectedOption ? selectedOption.value : null,
                  onClick: dispatch(selectFliterFunction({ filtername: selectedOption.label, filterId:selectedOption.value })) && handleclearInputbox(),
                }

              }, field)
            }

          
            isSearchable={!(selectState.name === "Delete" || selectState.name === "View")}
            styles={customStyles}
            placeholder="Select"
            required={field.required}
            id={field.id}
            name={field.name}
            onInput={(event) => validate(event, field, setIsValidateRequired)}
            onInvalid={(event) => validate(event, field, setIsValidateRequired)}
            // onKeyDown={(event) => handleEnterKeyPressMAaster(event, field, formDetails)}
            onKeyDown={(event,selectedOption) => handleEnterKeyPress(event, selectboxindex, field,selectedOption)}

            components={{ Menu: CustomMenu }}
            isDisabled={(selectState.name === "Delete" || selectState.name === "View")}

            onInputChange={(inputValue, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                console.log("Search input value:", inputValue);
                setsearchSingleComponent(inputValue);
                setSearchValue(inputValue);
                setMenuIsOpen(true)
                // You can store the search input value in state or use it as needed
              }
            }}
            onMenuOpen={handleFocus}  // Open on focus
            onMenuClose={handleBlur}  // Close on blur
            onFocus={handleFocus}     // Ensure dropdown opens on focus
            onClick={handleClick}
            inputValue={searchSingleComponent}
            onBlur={() => setMenuIsOpen(false)}
          // inputValue={searchValue}
          />
        ) : <Select styles={customStyles} />}
        {isValidateRequired &&
          <p className={`text-rose-600 bold text-right absolute top-1  ${arabicAlignMent.rightToLeft === 'rtl' ? 'right-[280px] sm:right-[380px]' : 'left-[280px] sm:left-[380px]'}`} style={{ fontSize: '10px' }}>
            {field.required === true ? <CheckCircleOutlineRoundedIcon
              className={`${field.required && (!formData[field.name] || formData[field.name] === '') ? 'text-red' : 'text-green-900'}`}
              style={{ fontSize: '15px' }}
            /> : null}

          </p>}

      </div>
    </div>
  );
}

export default forwardRef(SelectDropDown);
