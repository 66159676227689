import React, { useEffect, useState } from 'react';
import './master.css';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { selectFliterFunction } from '../../Redux/Reducer/filterSlice';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';

function MasterAccount({ accountList, searchValue, setSearchValue,getAccDataUpdate}) {
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const dispatch = useDispatch();

    // Check if accountList is updating correctly

    console.log("accounts",accountList);



    // Filter the account list based on the search query under active values
    // useEffect(() => {
    //     if (Array.isArray(accountList?.accSeleBox)) {
    //         const filtered = accountList?.accSeleBox?.map((account) => ({
    //             ...account,
    //             active: Array.isArray(account.active)
    //                 ? account.active.filter((item) => {
    //                     if (item?.name) {
    //                         return item.name.toLowerCase().includes(searchValue?.toLowerCase());
    //                     } else if (item?.label) {
    //                         return item.label.toLowerCase().includes(searchValue?.toLowerCase());
    //                     }
    //                     return false; // If neither name nor label is present
    //                 })
    //                 : [], // If active is not an array, set it as an empty array
    //         })).filter((account) => account.active.length > 0); // Only keep accounts with matching active items
    
    //         setFilteredAccounts(filtered);
    //     }
    // }, [accountList, searchValue]);



    useEffect(() => {
        if (Array.isArray(accountList?.accSeleBox)) {
            const filtered = accountList.accSeleBox.map((account) => ({
                ...account,
                active: Array.isArray(account.active)
                    ? account.active.filter((item) => {
                        // Check if item has accSelectFileds and compare apilabelkey to searchValue
                        const labelKey = item?.[accountList?.accSelectFileds?.apilabelkey];
                        return labelKey?.toLowerCase().includes(searchValue?.toLowerCase());
                    })
                    : [], // If active is not an array, set it as an empty array
            })).filter((account) => account.active.length > 0); // Only keep accounts with matching active items
    
            setFilteredAccounts(filtered);
        }
    }, [accountList, searchValue]);
    
    


    useEffect(() => {
        console.log("Updated accountList:", filteredAccounts);

    }, [accountList]);

    // const handleSelectAccountButton = (item,accountList) => {
    //     dispatch(selectFliterFunction({ filtername: item?.[accountList?.accSelectFileds?.apilabelkey], filterId: item?.[accountList?.accSelectFileds?.apidatakey]}));
    //     setSearchValue(''); // Optionally reset the search value after selection
        
    // };


    const handleSelectAccountButton = (item, accountList) => {
        // Dispatch the filter function with the selected item's label and data key
        dispatch(selectFliterFunction({
            filtername: item?.[accountList?.accSelectFileds?.apilabelkey], 
            filterId: item?.[accountList?.accSelectFileds?.apidatakey]
        }));
    
        // Optionally reset the search value after selection
        setSearchValue('');
    
        // Clone the object to avoid direct mutation
        const updatedAccData = { ...getAccDataUpdate };
        
        // Update the specific field in the cloned data object
        if (updatedAccData?.data?.[0]) {
            updatedAccData.data[0][accountList?.accSelectFileds?.name] = item?.[accountList?.accSelectFileds?.apidatakey];
        }
    
        // If necessary, update the state or perform any additional operations with the updated data
        // setGetAccDataUpdate(updatedAccData);  // Uncomment if you need to update state
    };
    

    const selectFilterName = useSelector((state) => state.Filternames);
    const darkModeState = useSelector((state) => state.darkMode);

    console.log("selectFilterName.filterId",selectFilterName.filterId)


    return (
        <div className='AccountMenuBgColor w-5/12 sm:hidden sm:h-[84%] lg:h-[84%] xl:h-[84%] overflow-y-auto mt-[95px] shadow'>
            <style>
                {`
                    .accountButtonStyle {
                        background: white;
                        width: 45px;
                        height: 45px;
                        box-shadow: inset 6px 1px 4px 2px #bfbbbb;
                    }

                    .selectedAccoundButton {
                        background: ${darkModeState.checkvalue ? dayTheme.DmenuButnColor : darkTheme.DmenuButnColor};
                        width: 45px;
                        height: 45px;
                        color: white;
                        box-shadow: inset 6px 1px 4px 2px #7e7e7e;
                    }
                `}
            </style>
            <div className='flex justify-end py-2'>
                <input
                    type="text"
                    className='rounded outline-none pl-3'
                    style={{ fontSize: '8px' }}
                    placeholder="Search accounts"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className='ml-3'>
                    <ManageSearchRoundedIcon className='text-white' style={{ fontSize: '25px' }} />
                </button>
            </div>
            <div>
                {filteredAccounts?.map((account) => (
                    <div key={account.id}>
                        <h1 className='ml-2 text-white font-bold'>{account.name}</h1>
                        <div className='grid grid-cols-5'>
                            {account?.active?.map((item) => (
                                <div key={item.id} className='divWidth flex flex-col items-center m-2'>
                                    <button
                                        className={`${selectFilterName.filterId == item?.[accountList?.accSelectFileds?.apidatakey] ? 'selectedAccoundButton' : 'accountButtonStyle'} rounded py-2 font-black`}
                                        style={{ fontSize: '15px' }}
                                        onClick={() => handleSelectAccountButton(item,accountList)}
                                    >
                                        {item?.[accountList?.accSelectFileds?.apilabelkey]?.charAt(0)?.toUpperCase() + item?.[accountList?.accSelectFileds?.apilabelkey]?.substr(1, 1)?.toLowerCase()}
                                    </button>
                                    <label className='text-center text-white font-bold' style={{ fontSize: '10px' }}>
                                        {item?.[accountList?.accSelectFileds?.apilabelkey]?.substr(0, 8)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MasterAccount;
